/////////////////////////////////////////////////////////
// Configurations related to listing.                  //
// Main configuration here is the extended data config //
/////////////////////////////////////////////////////////

// NOTE: if you want to change the structure of the data,
// you should also check src/util/configHelpers.js
// some validation is added there.

/**
 * Configuration options for listing fields (custom extended data fields):
 * - key:                           Unique key for the extended data field.
 * - scope (optional):              Scope of the extended data can be either 'public' or 'private'.
 *                                  Default value: 'public'.
 *                                  Note: listing doesn't support 'protected' scope atm.
 * - includeForListingTypes:        An array of listing types, for which the extended
 *   (optional)                     data is relevant and should be added.
 * - schemaType (optional):         Schema for this extended data field.
 *                                  This is relevant when rendering components and querying listings.
 *                                  Possible values: 'enum', 'multi-enum', 'text', 'long', 'boolean'.
 * - enumOptions (optional):        Options shown for 'enum' and 'multi-enum' extended data.
 *                                  These are used to render options for inputs and filters on
 *                                  EditListingPage, ListingPage, and SearchPage.
 * - filterConfig:                  Filter configuration for listings query.
 *    - indexForSearch (optional):    If set as true, it is assumed that the extended data key has
 *                                    search index in place. I.e. the key can be used to filter
 *                                    listing queries (then scope needs to be 'public').
 *                                    Note: Flex CLI can be used to set search index for the key:
 *                                    https://www.sharetribe.com/docs/references/extended-data/#search-schema
 *                                    Read more about filtering listings with public data keys from API Reference:
 *                                    https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
 *                                    Default value: false,
 *   - filterType:                    Sometimes a single schemaType can be rendered with different filter components.
 *                                    For 'enum' schema, filterType can be 'SelectSingleFilter' or 'SelectMultipleFilter'
 *   - label:                         Label for the filter, if the field can be used as query filter
 *   - searchMode (optional):         Search mode for indexed data with multi-enum schema.
 *                                    Possible values: 'has_all' or 'has_any'.
 *   - group:                         SearchPageWithMap has grouped filters. Possible values: 'primary' or 'secondary'.
 * - showConfig:                    Configuration for rendering listing. (How the field should be shown.)
 *   - label:                         Label for the saved data.
 *   - isDetail                       Can be used to hide detail row (of type enum, boolean, or long) from listing page.
 *                                    Default value: true,
 * - saveConfig:                    Configuration for adding and modifying extended data fields.
 *   - label:                         Label for the input field.
 *   - placeholderMessage (optional): Default message for user input.
 *   - isRequired (optional):         Is the field required for providers to fill
 *   - requiredMessage (optional):    Message for those fields, which are mandatory.
 */

export const ageArray = [];

for (let i = 18; i <= 70; i++) {
  ageArray.push({ key: i, label: i });
}

export const listingFieldsModals = [
  {
    key: 'isProfessional',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: "true", label: 'Professional Model' },
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectSingleFilter',
      label: 'Professional Model',
      group: 'primary',
    },
    showConfig: {
      label: 'Professional Model',
      isDetail: true,
    },
    saveConfig: {
      label: 'Professional Model',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select a Professional Model.',
    },
  },
];

///////////////////////////////////////////////////////////////////////
// Configurations related to listing types and transaction processes //
///////////////////////////////////////////////////////////////////////

// A presets of supported listing configurations
//
// Note 1: With first iteration of hosted configs, we are unlikely to support
//         multiple listing types, even though this template has some
//         rudimentary support for it.
// Note 2: transaction type is part of listing type. It defines what transaction process and units
//         are used when transaction is created against a specific listing.

/**
 * Configuration options for listing experience:
 * - listingType:         Unique string. This will be saved to listing's public data on
 *                        EditListingWizard.
 * - label                Label for the listing type. Used as microcopy for options to select
 *                        listing type in EditListingWizard.
 * - transactionType      Set of configurations how this listing type will behave when transaction is
 *                        created.
 *   - process              Transaction process.
 *                          The process must match one of the processes that this client app can handle
 *                          (check src/util/transactions/transaction.js) and the process must also exists in correct
 *                          marketplace environment.
 *   - alias                Valid alias for the aforementioned process. This will be saved to listing's
 *                          public data as transctionProcessAlias and transaction is initiated with this.
 *   - unitType             Unit type is mainly used as pricing unit. This will be saved to
 *                          transaction's protected data.
 *                          Recommendation: don't use same unit types in completely different processes
 *                          ('item' sold should not be priced the same as 'item' booked).
 * - stockType            This is relevant only to listings using default-purchase process.
 *                        If set to 'oneItem', stock management is not showed and the listing is
 *                        considered unique (stock = 1).
 *                        Possible values: 'oneItem' and 'multipleItems'.
 *                        Default: 'multipleItems'.
 * - defaultListingFields This is relevant only to listings using default-inquiry process atm.
 *                        It contains price: true/false value to indicate, whether price should be shown.
 *                        If defaultListingFields.price is not explicitly set to _false_, price will be shown.
 */

export const priceType = [
  { key: 'is-collaboration', label: "It's a collaboration" },
  { key: 'hourly-rate', label: 'Hourly Rate' },
  { key: 'flat-rate', label: 'Flat Rate' },
];

export const paymentInfoOptions = [
  { key: 'collaboration', label: 'Collaboration' },
  { key: 'paid', label: 'Paid' },
];
export const typeOptions = [
  { key: 'onlineJob', label: 'Online Job' },
  { key: 'in-person-job', label: 'In Person Job' },
];

export const genderOptions = [
  { key: 'male', label: 'Male' },
  { key: 'female', label: 'Female' },
  { key: 'non_binary', label: 'Non-binary' },
];

export const ethnicityOptions = [
  {key:'all-ehtnicity', label:'All Ethnicity'},
  { key: 'white-caucasian', label: 'White/Caucasian' },
  { key: 'black-african-american', label: 'Black/African American' },
  { key: 'latino-hispanic', label: 'Latino/Hispanic' },
  { key: 'native-american', label: 'Native American' },
  { key: 'asian', label: 'Asian' },
  { key: 'middle-eastern', label: 'Middle Eastern' },
];

export const hipsOptions = [
  { key: '25', label: '25' },
  { key: '25.5', label: '25.5' },
  { key: '26', label: '26' },
  { key: '26.5', label: '26.5' },
  { key: '27', label: '27' },
  { key: '27.5', label: '27.5' },
  { key: '28', label: '28' },
  { key: '28.5', label: '28.5' },
  { key: '29', label: '29' },
  { key: '29.5', label: '29.5' },
  { key: '30', label: '30' },
  { key: '30.5', label: '30.5' },
  { key: '31', label: '31' },
  { key: '31.5', label: '31.5' },
  { key: '32', label: '32' },
  { key: '32.5', label: '32.5' },
  { key: '33', label: '33' },
  { key: '33.5', label: '33.5' },
  { key: '34', label: '34' },
  { key: '34.5', label: '34.5' },
  { key: '35', label: '35' },
  { key: '35.5', label: '35.5' },
  { key: '36', label: '36' },
  { key: '36.5', label: '36.5' },
  { key: '37', label: '37' },
  { key: '37.5', label: '37.5' },
  { key: '38', label: '38' },
  { key: '38.5', label: '38.5' },
  { key: '39', label: '39' },
  { key: '39.5', label: '39.5' },
  { key: '40', label: '40' },
  { key: '40.5', label: '40.5' },
  { key: '41', label: '41' },
  { key: '41.5', label: '41.5' },
  { key: '42', label: '42' },
  { key: '42.5', label: '42.5' },
  { key: '43', label: '43' },
  { key: '43.5', label: '43.5' },
  { key: '44', label: '44' },
  { key: '44.5', label: '44.5' },
  { key: '45', label: '45' },
  { key: '45.5', label: '45.5' },
  { key: '46', label: '46' },
  { key: '46.5', label: '46.5' },
  { key: '47', label: '47' },
  { key: '47.5', label: '47.5' },
  { key: '48', label: '48' },
  { key: '48.5', label: '48.5' },
  { key: '49', label: '49' },
  { key: '49.5', label: '49.5' },
  { key: '50', label: '50' },
  { key: '50.5', label: '50.5' },
  { key: '51', label: '51' },
  { key: '51.5', label: '51.5' },
  { key: '52', label: '52' },
  { key: '52.5', label: '52.5' },
  { key: '53', label: '53' },
  { key: '53.5', label: '53.5' },
  { key: '54', label: '54' },
  { key: '54.5', label: '54.5' },
  { key: '55', label: '55' },
  { key: '55.5', label: '55.5' },
  { key: '56', label: '56' },
  { key: '56.5', label: '56.5' },
  { key: '57', label: '57' },
  { key: '57.5', label: '57.5' },
  { key: '58', label: '58' },
  { key: '58.5', label: '58.5' },
  { key: '59', label: '59' },
];

export const hairTypeOptions = [
  { key: 'black', label: 'Black' },
  { key: 'blonde', label: 'Blonde' },
  { key: 'brown', label: 'Brown' },
  { key: 'green', label: 'Green' },
  { key: 'red', label: 'Red' },
];
export const waistOptions = [
  { key: '20', label: '20' },
  { key: '20.5', label: '20.5' },
  { key: '21', label: '21' },
  { key: '21.5', label: '21.5' },
  { key: '22', label: '22' },
  { key: '22.5', label: '22.5' },
  { key: '23', label: '23' },
  { key: '23.5', label: '23.5' },
  { key: '24', label: '24' },
  { key: '24.5', label: '24.5' },
  { key: '25', label: '25' },
  { key: '25.5', label: '25.5' },
  { key: '26', label: '26' },
  { key: '26.5', label: '26.5' },
  { key: '27', label: '27' },
  { key: '27.5', label: '27.5' },
  { key: '28', label: '28' },
  { key: '28.5', label: '28.5' },
  { key: '29', label: '29' },
  { key: '29.5', label: '29.5' },
  { key: '30', label: '30' },
  { key: '30.5', label: '30.5' },
  { key: '31', label: '31' },
  { key: '31.5', label: '31.5' },
  { key: '32', label: '32' },
  { key: '32.5', label: '32.5' },
  { key: '33', label: '33' },
  { key: '33.5', label: '33.5' },
  { key: '34', label: '34' },
  { key: '34.5', label: '34.5' },
  { key: '35', label: '35' },
  { key: '35.5', label: '35.5' },
  { key: '36', label: '36' },
  { key: '36.5', label: '36.5' },
  { key: '37', label: '37' },
  { key: '37.5', label: '37.5' },
  { key: '38', label: '38' },
  { key: '38.5', label: '38.5' },
  { key: '39', label: '39' },
  { key: '39.5', label: '39.5' },
  { key: '40', label: '40' },
  { key: '41', label: '41' },
  { key: '42', label: '42' },
  { key: '43', label: '43' },
  { key: '44', label: '44' },
  { key: '45', label: '45' },
  { key: '46', label: '46' },
  { key: '47', label: '47' },
  { key: '48', label: '48' },
  { key: '49', label: '49' },
];
export const eyeOptions = [
  { key: 'black', label: 'Black' },
  { key: 'blue', label: 'Blue' },
  { key: 'brown', label: 'Brown' },
  { key: 'green', label: 'Green' },
  { key: 'gray', label: 'Gray' },
  { key: 'hazel', label: 'Hazel' },
];

export const shoesOptions = [
  { key: '4', label: '4' },
  { key: '4.5', label: '4.5' },
  { key: '5', label: '5' },
  { key: '5.5', label: '5.5' },
  { key: '6', label: '6' },
  { key: '6.5', label: '6.5' },
  { key: '7', label: '7' },
  { key: '7.5', label: '7.5' },
  { key: '8', label: '8' },
  { key: '8.5', label: '8.5' },
  { key: '9', label: '9' },
  { key: '9.5', label: '9.5' },
  { key: '10', label: '10' },
  { key: '10.5', label: '10.5' },
  { key: '11', label: '11' },
  { key: '11.5', label: '11.5' },
  { key: '12', label: '12' },
  { key: '12.5', label: '12.5' },
  { key: '13', label: '13' },
];

export const ageGroupOptions = [
  { key: '', label: 'Prefer not to answer' },
  { key: '20-29', label: '20s' },
  { key: '30-39', label: '30s' },
  { key: '40-49', label: '40s' },
  { key: '50-59', label: '50s' },
  { key: '60-69', label: '60s and above' },
];
export const expectedHours = [
  { key: '1 Hours', label: '1 Hours' },
  { key: '2 Hours', label: '2 Hours' },
  { key: '3 Hours', label: '3 Hours' },
  { key: '4 Hours', label: '4 Hours' },
  { key: '5 Hours', label: '5 Hours' },
  { key: '6 Hours', label: '6 Hours' },
  { key: '7 Hours', label: '7 Hours' },
  { key: '8 Hours', label: '8 Hours' },
  { key: '9 Hours', label: '9 Hours' },
  { key: '10 Hours', label: '10 Hours' },
  { key: '11 Hours', label: '11 Hours' },
  { key: '12 Hours', label: '12 Hours' },
  { key: '13 Hours', label: '13 Hours' },
  { key: '14 Hours', label: '14 Hours' },
  { key: '15 Hours', label: '15 Hours' },
  { key: '16 Hours', label: '16 Hours' },
  { key: '17 Hours', label: '17 Hours' },
  { key: '18 Hours', label: '18 Hours' },
  { key: '19 Hours', label: '19 Hours' },
  { key: '20 Hours', label: '20 Hours' },
  { key: '21 Hours', label: '21 Hours' },
  { key: '22 Hours', label: '22 Hours' },
  { key: '23 Hours', label: '23 Hours' },
  { key: '24 Hours', label: '24 Hours' },
];
export const bustChestOptions = [
  { key: '25', label: '25' },
  { key: '25.5', label: '25.5' },
  { key: '26', label: '26' },
  { key: '26.5', label: '26.5' },
  { key: '27', label: '27' },
  { key: '27.5', label: '27.5' },
  { key: '28', label: '28' },
  { key: '28.5', label: '28.5' },
  { key: '29', label: '29' },
  { key: '29.5', label: '29.5' },
  { key: '30', label: '30' },
  { key: '30.5', label: '30.5' },
  { key: '31', label: '31' },
  { key: '31.5', label: '31.5' },
  { key: '32', label: '32' },
  { key: '32.5', label: '32.5' },
  { key: '33', label: '33' },
  { key: '33.5', label: '33.5' },
  { key: '34', label: '34' },
  { key: '34.5', label: '34.5' },
  { key: '35', label: '35' },
  { key: '35.5', label: '35.5' },
  { key: '36', label: '36' },
  { key: '36.5', label: '36.5' },
  { key: '37', label: '37' },
  { key: '37.5', label: '37.5' },
  { key: '38', label: '38' },
  { key: '38.5', label: '38.5' },
  { key: '39', label: '39' },
  { key: '39.5', label: '39.5' },
  { key: '40', label: '40' },
  { key: '40.5', label: '40.5' },
  { key: '41', label: '41' },
  { key: '41.5', label: '41.5' },
  { key: '42', label: '42' },
  { key: '42.5', label: '42.5' },
  { key: '43', label: '43' },
  { key: '43.5', label: '43.5' },
  { key: '44', label: '44' },
  { key: '44.5', label: '44.5' },
  { key: '45', label: '45' },
  { key: '45.5', label: '45.5' },
  { key: '46', label: '46' },
  { key: '46.5', label: '46.5' },
  { key: '47', label: '47' },
  { key: '47.5', label: '47.5' },
  { key: '48', label: '48' },
  { key: '48.5', label: '48.5' },
  { key: '49', label: '49' },
  { key: '49.5', label: '49.5' },
  { key: '50', label: '50' },
  { key: '50.5', label: '50.5' },
  { key: '51', label: '51' },
  { key: '51.5', label: '51.5' },
  { key: '52', label: '52' },
  { key: '52.5', label: '52.5' },
  { key: '53', label: '53' },
  { key: '53.5', label: '53.5' },
  { key: '54', label: '54' },
  { key: '54.5', label: '54.5' },
  { key: '55', label: '55' },
  { key: '55.5', label: '55.5' },
  { key: '56', label: '56' },
  { key: '56.5', label: '56.5' },
  { key: '57', label: '57' },
  { key: '57.5', label: '57.5' },
  { key: '58', label: '58' },
  { key: '58.5', label: '58.5' },
  { key: '59', label: '59' },
];

export const whichOneClientOptions = [
  {
    key: 'advertising agency',
    label: 'Advertising Agency',
  },
  {
    key: 'brand',
    label: 'Brand',
  },
  {
    key: 'casting director',
    label: 'Casting Director',
  },
  {
    key: 'hair-and-make-up-artist',
    label: 'Hair & Make-up Artist',
  },
  {
    key: 'model-agency',
    label: 'Model Agency',
  },
  {
    key: 'production-company',
    label: 'Production Company',
  },
];

export const whichOneModelOptions = [
  { key: 'aspiring-model', label: 'Aspiring model' },
  { key: 'professional-model', label: 'Professional model' },
  {
    key: 'talent(Actor,dancer,musician,sports person,etc)',
    label: 'Talent (Actor, Dancer, Musician, Athlete, etc.)',
  },
];

export const heightOptions = [
  { key: '4.0', label: "4' 0''" },
  { key: '4.1', label: "4' 1''" },
  { key: '4.2', label: "4' 2''" },
  { key: '4.3', label: "4' 3''" },
  { key: '4.4', label: "4' 4''" },
  { key: '4.5', label: "4' 5''" },
  { key: '4.6', label: "4' 6''" },
  { key: '4.7', label: "4' 7''" },
  { key: '4.8', label: "4' 8''" },
  { key: '4.9', label: "4' 9''" },
  { key: '4.10', label: "4' 10'" },
  { key: '4.11', label: "4' 11'" },
  { key: '5.0', label: "5' 0''" },
  { key: '5.1', label: "5' 1''" },
  { key: '5.2', label: "5' 2''" },
  { key: '5.3', label: "5' 3''" },
  { key: '5.4', label: "5' 4''" },
  { key: '5.5', label: "5' 5''" },
  { key: '5.6', label: "5' 6''" },
  { key: '5.7', label: "5' 7''" },
  { key: '5.8', label: "5' 8''" },
  { key: '5.9', label: "5' 9''" },
  { key: '5.10', label: "5' 10" },
  { key: '5.11', label: "5' 11" },
  { key: '6.0', label: "6' 0''" },
  { key: '6.1', label: "6' 1''" },
  { key: '6.2', label: "6' 2''" },
  { key: '6.3', label: "6' 3''" },
  { key: '6.4', label: "6' 4''" },
  { key: '6.5', label: "6' 5''" },
  { key: '6.6', label: "6' 6''" },
  { key: '6.7', label: "6' 7''" },
  { key: '6.8', label: "6' 8''" },
  { key: '6.9', label: "6' 9''" },
];

export const allNationalities = [
  { key: 'afghan', label: 'Afghan' },
  { key: 'albanian', label: 'Albanian' },
  { key: 'algerian', label: 'Algerian' },
  { key: 'andorran', label: 'Andorran' },
  { key: 'angolan', label: 'Angolan' },
  { key: 'antiguan', label: 'Antiguan' },
  { key: 'barbudan', label: 'Barbudan' },
  { key: 'argentinian', label: 'Argentinian' },
  { key: 'armenian', label: 'Armenian' },
  { key: 'australian', label: 'Australian' },
  { key: 'austrian', label: 'Austrian' },
  { key: 'azerbaijani', label: 'Azerbaijani' },
  { key: 'bahamian', label: 'Bahamian' },
  { key: 'bahraini', label: 'Bahraini' },
  { key: 'bangladeshi', label: 'Bangladeshi' },
  { key: 'barbadian', label: 'Barbadian' },
  { key: 'belarusian', label: 'Belarusian' },
  { key: 'belgian', label: 'Belgian' },
  { key: 'belizean', label: 'Belizean' },
  { key: 'beninese', label: 'Beninese' },
  { key: 'bhutanese', label: 'Bhutanese' },
  { key: 'bolivian', label: 'Bolivian' },
  { key: 'bosnian', label: 'Bosnian' },
  { key: 'herzegovinian', label: 'Herzegovinian' },
  { key: 'botswanan', label: 'Botswanan' },
  { key: 'brazilian', label: 'Brazilian' },
  { key: 'bruneian', label: 'Bruneian' },
  { key: 'bulgarian', label: 'Bulgarian' },
  { key: 'burkinabe', label: 'Burkinabe' },
  { key: 'burundian', label: 'Burundian' },
  { key: 'cape verdean', label: 'Cape Verdean' },
  { key: 'cambodian', label: 'Cambodian' },
  { key: 'cameroonian', label: 'Cameroonian' },
  { key: 'canadian', label: 'Canadian' },
  { key: 'central african', label: 'Central African' },
  { key: 'chadian', label: 'Chadian' },
  { key: 'chilean', label: 'Chilean' },
  { key: 'chinese', label: 'Chinese' },
  { key: 'colombian', label: 'Colombian' },
  { key: 'comoran', label: 'Comoran' },
  { key: 'congolese', label: 'Congolese' },
  { key: 'costa rican', label: 'Costa Rican' },
  { key: 'croatian', label: 'Croatian' },
  { key: 'cuban', label: 'Cuban' },
  { key: 'cypriot', label: 'Cypriot' },
  { key: 'czech', label: 'Czech' },
  { key: 'danish', label: 'Danish' },
  { key: 'djiboutian', label: 'Djiboutian' },
  { key: 'dominican', label: 'Dominican' },
  { key: 'ecuadorian', label: 'Ecuadorian' },
  { key: 'egyptian', label: 'Egyptian' },
  { key: 'salvadoran', label: 'Salvadoran' },
  { key: 'equatorial guinean', label: 'Equatorial Guinean' },
  { key: 'eritrean', label: 'Eritrean' },
  { key: 'estonian', label: 'Estonian' },
  { key: 'swazi', label: 'Swazi' },
  { key: 'ethiopian', label: 'Ethiopian' },
  { key: 'fijian', label: 'Fijian' },
  { key: 'finnish', label: 'Finnish' },
  { key: 'french', label: 'French' },
  { key: 'gabonese', label: 'Gabonese' },
  { key: 'gambian', label: 'Gambian' },
  { key: 'georgian', label: 'Georgian' },
  { key: 'german', label: 'German' },
  { key: 'ghanaian', label: 'Ghanaian' },
  { key: 'greek', label: 'Greek' },
  { key: 'grenadian', label: 'Grenadian' },
  { key: 'guatemalan', label: 'Guatemalan' },
  { key: 'guinean', label: 'Guinean' },
  { key: 'guinea-bissauan', label: 'Guinea-Bissauan' },
  { key: 'guyanese', label: 'Guyanese' },
  { key: 'haitian', label: 'Haitian' },
  { key: 'honduran', label: 'Honduran' },
  { key: 'hungarian', label: 'Hungarian' },
  { key: 'icelander', label: 'Icelander' },
  { key: 'indian', label: 'Indian' },
  { key: 'indonesian', label: 'Indonesian' },
  { key: 'iranian', label: 'Iranian' },
  { key: 'iraqi', label: 'Iraqi' },
  { key: 'irish', label: 'Irish' },
  { key: 'israeli', label: 'Israeli' },
  { key: 'italian', label: 'Italian' },
  { key: 'jamaican', label: 'Jamaican' },
  { key: 'japanese', label: 'Japanese' },
  { key: 'jordanian', label: 'Jordanian' },
  { key: 'kazakhstani', label: 'Kazakhstani' },
  { key: 'kenyan', label: 'Kenyan' },
  { key: 'i-kiribati', label: 'I-Kiribati' },
  { key: 'north korean', label: 'North Korean' },
  { key: 'south korean', label: 'South Korean' },
  { key: 'kosovar', label: 'Kosovar' },
  { key: 'kuwaiti', label: 'Kuwaiti' },
  { key: 'kyrgyz', label: 'Kyrgyz' },
  { key: 'laotian', label: 'Laotian' },
  { key: 'latvian', label: 'Latvian' },
  { key: 'lebanese', label: 'Lebanese' },
  { key: 'basotho', label: 'Basotho' },
  { key: 'liberian', label: 'Liberian' },
  { key: 'libyan', label: 'Libyan' },
  { key: 'liechtensteiner', label: 'Liechtensteiner' },
  { key: 'lithuanian', label: 'Lithuanian' },
  { key: 'luxembourger', label: 'Luxembourger' },
  { key: 'malagasy', label: 'Malagasy' },
  { key: 'malawian', label: 'Malawian' },
  { key: 'malaysian', label: 'Malaysian' },
  { key: 'maldivian', label: 'Maldivian' },
  { key: 'malian', label: 'Malian' },
  { key: 'maltese', label: 'Maltese' },
  { key: 'marshallese', label: 'Marshallese' },
  { key: 'mauritanian', label: 'Mauritanian' },
  { key: 'mauritian', label: 'Mauritian' },
  { key: 'mexican', label: 'Mexican' },
  { key: 'micronesian', label: 'Micronesian' },
  { key: 'moldovan', label: 'Moldovan' },
  { key: 'monegasque', label: 'Monegasque' },
  { key: 'mongolian', label: 'Mongolian' },
  { key: 'montenegrin', label: 'Montenegrin' },
  { key: 'moroccan', label: 'Moroccan' },
  { key: 'mozambican', label: 'Mozambican' },
  { key: 'burmese', label: 'Burmese' },
  { key: 'namibian', label: 'Namibian' },
  { key: 'nauruan', label: 'Nauruan' },
  { key: 'nepalese', label: 'Nepalese' },
  { key: 'dutch', label: 'Dutch' },
  { key: 'new zealander', label: 'New Zealander' },
  { key: 'nicaraguan', label: 'Nicaraguan' },
  { key: 'nigerien', label: 'Nigerien' },
  { key: 'nigerian', label: 'Nigerian' },
  { key: 'north macedonian', label: 'North Macedonian' },
  { key: 'norwegian', label: 'Norwegian' },
  { key: 'omani', label: 'Omani' },
  { key: 'pakistani', label: 'Pakistani' },
  { key: 'palauan', label: 'Palauan' },
  { key: 'palestinian', label: 'Palestinian' },
  { key: 'panamanian', label: 'Panamanian' },
  { key: 'papua new guinean', label: 'Papua New Guinean' },
  { key: 'paraguayan', label: 'Paraguayan' },
  { key: 'peruvian', label: 'Peruvian' },
  { key: 'filipino', label: 'Filipino' },
  { key: 'polish', label: 'Polish' },
  { key: 'portuguese', label: 'Portuguese' },
  { key: 'qatari', label: 'Qatari' },
  { key: 'romanian', label: 'Romanian' },
  { key: 'russian', label: 'Russian' },
  { key: 'rwandan', label: 'Rwandan' },
  { key: 'kittitian', label: 'Kittitian' },
  { key: 'nevisian', label: 'Nevisian' },
  { key: 'saint lucian', label: 'Saint Lucian' },
  { key: 'saint vincentian', label: 'Saint Vincentian' },
  { key: 'samoan', label: 'Samoan' },
  { key: 'sammarinese', label: 'Sammarinese' },
  { key: 'sao tomean', label: 'Sao Tomean' },
  { key: 'saudi', label: 'Saudi' },
  { key: 'senegalese', label: 'Senegalese' },
  { key: 'serbian', label: 'Serbian' },
  { key: 'seychellois', label: 'Seychellois' },
  { key: 'sierra leonean', label: 'Sierra Leonean' },
  { key: 'singaporean', label: 'Singaporean' },
  { key: 'slovak', label: 'Slovak' },
  { key: 'slovenian', label: 'Slovenian' },
  { key: 'solomon islander', label: 'Solomon Islander' },
  { key: 'somali', label: 'Somali' },
  { key: 'south african', label: 'South African' },
  { key: 'south sudanese', label: 'South Sudanese' },
  { key: 'spanish', label: 'Spanish' },
  { key: 'sri lankan', label: 'Sri Lankan' },
  { key: 'sudanese', label: 'Sudanese' },
  { key: 'surinamese', label: 'Surinamese' },
  { key: 'swedish', label: 'Swedish' },
  { key: 'swiss', label: 'Swiss' },
  { key: 'syrian', label: 'Syrian' },
  { key: 'taiwanese', label: 'Taiwanese' },
  { key: 'tajik', label: 'Tajik' },
  { key: 'tanzanian', label: 'Tanzanian' },
  { key: 'thai', label: 'Thai' },
  { key: 'timorese', label: 'Timorese' },
  { key: 'togolese', label: 'Togolese' },
  { key: 'tongan', label: 'Tongan' },
  { key: 'trinidadian', label: 'Trinidadian' },
  { key: 'tobagonian', label: 'Tobagonian' },
  { key: 'tunisian', label: 'Tunisian' },
  { key: 'turkish', label: 'Turkish' },
  { key: 'turkmen', label: 'Turkmen' },
  { key: 'tuvaluan', label: 'Tuvaluan' },
  { key: 'ugandan', label: 'Ugandan' },
  { key: 'ukrainian', label: 'Ukrainian' },
  { key: 'emirati', label: 'Emirati' },
  { key: 'british', label: 'British' },
  { key: 'american', label: 'American' },
  { key: 'uruguayan', label: 'Uruguayan' },
  { key: 'uzbek', label: 'Uzbek' },
  { key: 'vanuatuan', label: 'Vanuatuan' },
  { key: 'vatican', label: 'Vatican' },
  { key: 'venezuelan', label: 'Venezuelan' },
  { key: 'vietnamese', label: 'Vietnamese' },
  { key: 'yemeni', label: 'Yemeni' },
  { key: 'zambian', label: 'Zambian' },
  { key: 'zimbabwean', label: 'Zimbabwean' },
];
export const timeOptions = [
  { key: '00:00 AM', label: '00:00 AM' },
  { key: '01:00 AM', label: '01:00 AM' },
  { key: '02:00 AM', label: '02:00 AM' },
  { key: '03:00 AM', label: '03:00 AM' },
  { key: '04:00 AM', label: '04:00 AM' },
  { key: '05:00 AM', label: '05:00 AM' },
  { key: '06:00 AM', label: '06:00 AM' },
  { key: '07:00 AM', label: '07:00 AM' },
  { key: '08:00 AM', label: '08:00 AM' },
  { key: '09:00 AM', label: '09:00 AM' },
  { key: '10:00 AM', label: '10:00 AM' },
  { key: '11:00 AM', label: '11:00 AM' },
  { key: '12:00 PM', label: '12:00 PM' },
  { key: '01:00 PM', label: '01:00 PM' },
  { key: '02:00 PM', label: '02:00 PM' },
  { key: '03:00 PM', label: '03:00 PM' },
  { key: '04:00 PM', label: '04:00 PM' },
  { key: '05:00 PM', label: '05:00 PM' },
  { key: '06:00 PM', label: '06:00 PM' },
  { key: '07:00 PM', label: '07:00 PM' },
  { key: '08:00 PM', label: '08:00 PM' },
  { key: '09:00 PM', label: '09:00 PM' },
  { key: '10:00 PM', label: '10:00 PM' },
  { key: '11:00 PM', label: '11:00 PM' },
];

export const listingTypes = [
  {
    listingType: 'daily-booking',
    label: 'Daily booking',
    transactionType: {
      process: 'default-booking',
      alias: 'default-booking/release-1',
      unitType: 'day',
    },
  },
  // // Here are some examples for other listingTypes
  // // TODO: SearchPage does not work well if both booking and product selling are used at the same time
  // {
  //   listingType: 'nightly-booking',
  //   label: 'Nightly booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'night',
  //   },
  // },
  // {
  //   listingType: 'hourly-booking',
  //   label: 'Hourly booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'hour',
  //   },
  // },
  // {
  //   listingType: 'product-selling',
  //   label: 'Sell bicycles',
  //   transactionType: {
  //     process: 'default-purchase',
  //     alias: 'default-purchase/release-1',
  //     unitType: 'item',
  //   },
  //   stockType: 'multipleItems',
  // },
  // {
  //   listingType: 'inquiry',
  //   label: 'Inquiry',
  //   transactionType: {
  //     process: 'default-inquiry',
  //     alias: 'default-inquiry/release-1',
  //     unitType: 'inquiry',
  //   },
  //   defaultListingFields: {
  //     price: false,
  //   },
  // },
];

// SearchPage can enforce listing query to only those listings with valid listingType
// However, it only works if you have set 'enum' type search schema for the public data fields
//   - listingType
//
//  Similar setup could be expanded to 2 other extended data fields:
//   - transactionProcessAlias
//   - unitType
//
// Read More:
// https://www.sharetribe.com/docs/how-to/manage-search-schemas-with-flex-cli/#adding-listing-search-schemas
export const enforceValidListingType = false;
