//////////////////////////////////////////////////////////////////////
// Configurations related to search                                 //
// Note: some search experience is also on defaultMapsConfig        //
// and defaultListingConfig.js is responsible public data filtering //
//////////////////////////////////////////////////////////////////////

// NOTE: if you want to change the structure of the data,
// you should also check src/util/configHelpers.js
// some validation is added there.

// Main search used in Topbar.
// This can be either 'keywords' or 'location'.
export const mainSearch = {
  searchType: 'location',
};

/**
 * Configuration for default filters.
 * These are custom configs for each filter.
 * Common properties: key, schemaType, and label.
 * Note: the order of default filters is defined in util/configHelpers.js
 */

export const dateRangeFilter = {
  schemaType: 'dates',
  // Should the entire date range be available, or just part of it
  // Note: Since we don't enforce location search for dates filtering,
  //       we don't use API's 'time-full' in actual queries. It would require time zone info.
  availability: 'time-full', // time-partial
  // Options: day/night. This affects counting and whether single day picking is possible.
  dateRangeMode: 'day',
};

export const priceFilter = {
  schemaType: 'price',
  // Note: unlike most prices this is not handled in subunits
  min: 0,
  max: 1000,
  step: 5,
};

export const ageFilter = {
  enabled : true,
  // Note: unlike most prices this is not handled in subunits
  min: 16,
  max: 100,
  schemaType: 'pub_age',
  step: 5,
};
export const heightFilter = {
  enabled : true,
  // Note: unlike most prices this is not handled in subunits
  min: 4,
  max: 7,
  schemaType: 'pub_height',
  step: 0.1,
};
export const hipsFilter = {
  enabled : true,
  // Note: unlike most prices this is not handled in subunits
  min: 25,
  max: 59,
  schemaType: 'pub_hips',
  step: 0.5,
};

export const bustChestFilter = {
  enabled : true,
  // Note: unlike most prices this is not handled in subunits
  min: 25,
  max: 59,
  schemaType: 'pub_bustChest',
  step: 0.5,
};

export const shoesFilter = {
  enabled : true,
  // Note: unlike most prices this is not handled in subunits
  min: 4,
  max: 13,
  schemaType: 'pub_shoes',
  step: 0.5,
};
export const waistFilter = {
  enabled : true,
  // Note: unlike most prices this is not handled in subunits
  min: 20,
  max: 49,
  schemaType: 'pub_waist',
  step: 0.5,
};
// // This is not in use by default.
// export const keywordsFilter = {
//   key: 'keywords',
//   schemaType: 'keywords',
// }

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Marketplace API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Relevance key is used with keywords filter.
  // Keywords filter also sorts results according to relevance.
  relevanceFilter: 'keywords',

  // Keyword filter is sorting the results by relevance.
  // If keyword filter is active, one might want to disable other sorting options
  // by adding 'keywords' to this list.
  conflictingFilters: [],

  options: [
    // These are default sort options
    { key: 'createdAt', labelTranslationKey: 'SortBy.newest' },
    { key: '-price', labelTranslationKey: 'SortBy.lowestPrice' },
    { key: 'price', labelTranslationKey: 'SortBy.highestPrice' },
    { key: 'pub_age', labelTranslationKey: 'SortBy.maximumAge' },
    { key: '-pub_age', labelTranslationKey: 'SortBy.minimumAge' },
    { key: 'pub_height', labelTranslationKey: 'SortBy.maximumHeight' },
    { key: '-pub_height', labelTranslationKey: 'SortBy.minimumHeight' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    {
      key: 'relevance',
      labelTranslationKey: 'SortBy.relevance',
      labelTranslationKeyLong: 'SortBy.relevanceLong',
    },
  ],
};

export const filterClientSorts = ["pub_ageRange","pub_height"];
export const filterModalSorts = ["relevance","price","-price"];
