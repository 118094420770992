import React, { useEffect, useState } from 'react';
import css from './ManageSubscriptionPanel.module.css';
import { FormattedMessage } from '../../util/reactIntl';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Button from '../Button/Button';
import { getSubscriptions } from '../../containers/StripeSubscriptionPage/StripeSubscriptionPage.duck';
import { cancelStripeSubscription } from '../../util/api';
import {
  PROFESSIONAL_HALFYEARLY,
  SUBSCRIPTION_STATUS_ACTIVE,
  SUBSCRIPTION_STATUS_CANCELLED,
  SUBSCRIPTION_STATUS_TRIAL,
  PROFESSIONAL_ANNUALY,
  PROFESSIONAL_MONTHLY,
} from '../../util/types';
import { firstLetterCapital, getStripeCustomerId } from '../../util/helper';
import NamedLink from '../NamedLink/NamedLink';
import SkeletonLoader from '../SkeletonLoader/SkeletonLoader';
import { createResourceLocatorString } from '../../util/routes';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { updateProfile } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';
import { getSubscriptionArray } from '../../util/data';
import Modal from '../Modal/Modal';

const ManageSubscriptionPanel = ({ history }) => {
  const routeConfiguration = useRouteConfiguration();
  const [userSubscriptions, setUserSubscriptions] = useState([]);
  const [cancelModal, setCancelModal] = useState(false);
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state?.user?.currentUser);
  const onlineSubscriptionData = userSubscriptions?.find(plan => plan);
  const id = currentUser?.id?.uuid;

  useEffect(() => {
    const fetchSubscriptions = async () => {
      try {
        const stripeCustomerId =
          currentUser && currentUser?.attributes?.profile?.protectedData?.stripeCustomerId;
        const subscriptions = await dispatch(getSubscriptions({ stripeCustomerId }));
        if (subscriptions?.length) {
          setUserSubscriptions(subscriptions);
        }
      } catch (error) {
        console.error('Error fetching subscriptions:', error);
      }
    };

    if (currentUser?.id) {
      fetchSubscriptions();
    }
  }, [dispatch, currentUser?.id]);

  const manageSubscription = async subscriptionId => {
    try {
      const response = await cancelStripeSubscription({ subscriptionId: subscriptionId });
      if (response.status === 'canceled') {
        history.push(
          createResourceLocatorString('StripeSubscriptionPage', routeConfiguration, {}, {})
        );
      }
    } catch (err) {
      console.log(err, '&&& &&& => err');
    }
  };

  const activeSubscription = getSubscriptionArray(currentUser);

  const currentSubscription = userSubscriptions?.filter(e =>
    [SUBSCRIPTION_STATUS_ACTIVE, SUBSCRIPTION_STATUS_CANCELLED, SUBSCRIPTION_STATUS_TRIAL].includes(
      e.status
    )
  );

  const subscribedPlan = userSubscriptions?.map(st => st.plan);
  const planName = userSubscriptions?.map(st => st.plan.nickname);
  const planData = currentSubscription?.length && currentSubscription[0]?.plan;

  const isPlanCancelled =
    currentSubscription?.length && currentSubscription[0]?.cancel_at_period_end;
  const cancelAt =
    currentSubscription?.length &&
    moment.unix(currentSubscription[0]?.cancel_at).format('MMM D, YYYY');
  const subscriptionId = activeSubscription?.length && activeSubscription[0]?.id;

  const { isFreePlanUsed } = currentUser?.attributes?.profile?.protectedData?.freePlanData || {};

  const getStatus = () => {
    if (currentSubscription?.length && currentSubscription[0]?.status && !isPlanCancelled) {
      return firstLetterCapital(currentSubscription[0]?.status);
    } else if (isFreePlanUsed) {
      return 'Active';
    } else if (isPlanCancelled) {
      return 'Cancelled';
    }
    return 'Inactive';
  };

  const subscriptionStatus = getStatus();

  const amountAsPerSubscriptionPlan =
    onlineSubscriptionData?.metadata.currency !== 'INR' && planData?.amount
      ? (planData?.amount / 100) * currentSubscription[0]?.quantity
      : 0;

  const subscriptionEndDate =
    currentUser &&
    moment.unix(currentUser.attributes?.profile?.protectedData?.freePlanData?.endDate);
  const formattedEndDate = subscriptionEndDate?.format('YYYY-MM-DD ');

  const cancelFreeSubscription = () => {
    const profile = {
      protectedData: {
        freePlanData: {
          isFreePlanUsed: false,
        },
      },
    };

    try {
      history.push(
        createResourceLocatorString('StripeSubscriptionPage', routeConfiguration, {}, {})
      );
      dispatch(updateProfile(profile));
    } catch (err) {
      console.log(err, 'err');
    }
  };

  const handleCancelSubscription = () => {
    if (onlineSubscriptionData?.id) {
      manageSubscription(subscriptionId);
      const profile = {
        protectedData: {
          freePlanData: {
            isFreePlanUsed: false,
          },
        },
      };
      dispatch(updateProfile(profile));
      setCancelModal(false);
    } else {
      cancelFreeSubscription();
    }
  };

  return (
    <>
      {userSubscriptions?.length || isFreePlanUsed ? (
        <div className={css.manageSubscriptionPanel}>
          <div className={css.headingTopBoxs}>
            <div className={css.billingInformationTitle}>
              <FormattedMessage id="ManageSubscriptionPanel.billingInformation" />
            </div>

            {!onlineSubscriptionData?.id ? null : (
              <NamedLink
                name="StripeSubscriptionPage"
                className={css.subscriptionPageRedirectButton}
              >
                <FormattedMessage id="ManageSubscriptionPanel.subscriptionPageRedirectButton" />
              </NamedLink>
            )}

            {!isFreePlanUsed || onlineSubscriptionData?.id ? null : (
              <NamedLink
                name="StripeSubscriptionPage"
                className={css.subscriptionPageRedirectButton}
              >
                <FormattedMessage id="ManageSubscriptionPanel.subscriptionPageRedirectButton" />
              </NamedLink>
            )}
          </div>
          {userSubscriptions?.length ? (
            <div>
              <h3 className={css.nameHeading}>
                <FormattedMessage id="ManageSubscriptionPanel.onlinePlanHeading" />
              </h3>
              {userSubscriptions.map(sub => {
                const subPrice =
                  sub?.plan?.nickname == 'Annual Plan'
                    ? (sub?.plan?.amount / 100 / 12).toFixed(2)
                    : sub?.plan?.nickname == 'Half Year Plan'
                    ? (sub?.plan?.amount / 100 / 6).toFixed(2)
                    : sub?.plan?.amount / 100;
                console.log(sub, '&&& &&& => sub');

                return (
                  <div className={css.planWrapper} key={sub.id}>
                    <div className={css.planCard}>
                      <div className={css.wrapperBox}>
                        <div className={css.mobilePlanHeading}>
                          <div
                            className={
                              !onlineSubscriptionData?.id || isPlanCancelled
                                ? css.inMobileInActiveBadge
                                : css.mobileActive
                            }
                          >
                            {sub?.cancel_at_period_end
                              ? 'Cancelled'
                              : firstLetterCapital(sub?.status)}
                          </div>
                          <div className={css.amountSub}>
                            <div className={css.planPrice}>
                              <span className={css.price}>
                                {'$' + (sub.plan?.amount ? sub.plan?.amount / 100 : 0)}
                              </span>
                              <span className={css.planPeriod}>
                                {sub?.plan?.nickname === PROFESSIONAL_MONTHLY
                                  ? 'per month'
                                  : sub?.plan?.nickname === PROFESSIONAL_ANNUALY
                                  ? 'per month'
                                  : sub?.plan?.nickname === PROFESSIONAL_HALFYEARLY
                                  ? 'per month'
                                  : 'basic'}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className={css.planHeading}>
                          <div className={css.planLeftSide}>
                            <div className={css.planHeadingBox}>
                              <div style={{ display: 'flex' }}>
                                {sub?.plan?.nickname?.length && <h3>{sub?.plan?.nickname}</h3>}
                                <span
                                  className={
                                    !onlineSubscriptionData?.id ||
                                    isPlanCancelled ||
                                    !isFreePlanUsed
                                      ? css.inActiveBadge
                                      : css.activeBadge
                                  }
                                >
                                  {sub?.cancel_at_period_end
                                    ? 'Cancelled'
                                    : firstLetterCapital(sub?.status)}
                                </span>
                              </div>
                              <div className={css.planRightSide}>
                                <div className={css.planPrice}>
                                  <span className={css.price}>$ {subPrice}</span>
                                  <span className={css.planPeriod}>
                                    {sub?.plan?.nickname === PROFESSIONAL_MONTHLY
                                      ? 'per month'
                                      : sub?.plan?.nickname === PROFESSIONAL_ANNUALY
                                      ? 'per month'
                                      : sub?.plan?.nickname === PROFESSIONAL_HALFYEARLY
                                      ? 'per month'
                                      : 'basic'}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <p className={css.planDescription}>
                              {sub?.plan?.nickname === 'Monthly Plan'
                                ? 'Billed monthly. Apply to castings and auditions. No access to classrooms.'
                                : sub?.plan?.nickname === PROFESSIONAL_ANNUALY
                                ? 'Based on your yearly billing of $83.88 . unlimited access to casting,audition and classroom.'
                                : sub?.plan?.nickname === PROFESSIONAL_HALFYEARLY
                                ? 'Based on 6 monthly billing of $65.94. Apply to castings and auditions. No access to classrooms.'
                                : null}
                            </p>
                          </div>
                        </div>
                        <div className={css.teamInformation}></div>
                      </div>
                      <div className={css.planBody}>
                        {onlineSubscriptionData?.id || isFreePlanUsed ? (
                          <div className={css.planBodyRight}>
                            {sub.cancel_at_period_end ? null : (
                              <Button
                                onClick={() => {
                                  setCancelModal(true);
                                }}
                                className={css.subscriptionButton}
                                // disabled={true}
                              >
                                <FormattedMessage id="ManageSubscriptionPanel.cancelSubscriptionButton" />
                              </Button>
                            )}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div>
              <div className={css.planWrapper}>
                <h3 className={css.nameHeading}>
                  <FormattedMessage id="ManageSubscriptionPanel.onlinePlanHeading" />
                </h3>
                <div className={css.planCard}>
                  <div className={css.wrapperBox}>
                    <div className={css.mobilePlanHeading}>
                      <div
                        className={
                          !onlineSubscriptionData?.id || isPlanCancelled
                            ? css.inMobileInActiveBadge
                            : css.mobileActive
                        }
                      >
                        {subscriptionStatus}
                      </div>
                      <div className={css.amountSub}>
                        <div className={css.planPrice}>
                          <span className={css.price}>{'$' + amountAsPerSubscriptionPlan}</span>
                          <span className={css.planPeriod}>
                            {planData?.nickname === PROFESSIONAL_MONTHLY
                              ? 'per month'
                              : planData?.nickname === PROFESSIONAL_ANNUALY
                              ? 'per year'
                              : planData?.nickname === PROFESSIONAL_HALFYEARLY
                              ? 'per month'
                              : 'basic'}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className={css.planHeading}>
                      <div className={css.planLeftSide}>
                        <div className={css.planHeadingBox}>
                          {planData?.nickname?.length && <h3>{planData?.nickname}</h3>}
                          <span
                            className={
                              !onlineSubscriptionData?.id || isPlanCancelled || !isFreePlanUsed
                                ? css.inActiveBadge
                                : css.activeBadge
                            }
                          >
                            {subscriptionStatus}
                          </span>
                        </div>
                        <p className={css.planDescription}>
                          {[
                            PROFESSIONAL_MONTHLY,
                            PROFESSIONAL_ANNUALY,
                            PROFESSIONAL_HALFYEARLY,
                          ]?.includes(planName) ? (
                            <FormattedMessage id="ManageSubscriptionPanel.individualPlanInformation" />
                          ) : (
                            <FormattedMessage id="ManageSubscriptionPanel.freePlanInformation" />
                          )}
                        </p>
                      </div>
                      <div className={css.planRightSide}>
                        <div className={css.planPrice}>
                          <span className={css.price}>${amountAsPerSubscriptionPlan}</span>
                          <span className={css.planPeriod}>
                            {planData?.nickname === PROFESSIONAL_MONTHLY
                              ? 'per month'
                              : planData?.nickname === PROFESSIONAL_ANNUALY
                              ? 'per year'
                              : planData?.nickname === PROFESSIONAL_HALFYEARLY
                              ? 'per month'
                              : 'basic'}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className={css.teamInformation}></div>
                  </div>
                  <div className={css.planBody}>
                    {onlineSubscriptionData?.id || isFreePlanUsed ? (
                      <div className={css.planBodyRight}>
                        <Button
                          onClick={() => {
                            setCancelModal(true);
                          }}
                          className={css.subscriptionButton}
                          // disabled={true}
                        >
                          <FormattedMessage id="ManageSubscriptionPanel.cancelSubscriptionButton" />
                        </Button>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          )}

          {onlineSubscriptionData?.id ? (
            <div>
              <h3 className={css.nameHeading}>
                <FormattedMessage id="ManageSubscriptionPanel.billingHistory" />
              </h3>
              <div className={css.tableBox}>
                <table className={css.tableStriped}>
                  <thead>
                    <tr className={css.subscriptionHeading}>
                      <th className={css.leftTd}>
                        <FormattedMessage id="ManageSubscriptionPanel.planHeading" />
                      </th>
                      <th className={css.centerTd}>
                        <FormattedMessage id="ManageSubscriptionPanel.billingDetailsHeading" />
                      </th>
                      <th className={css.rightTd}>
                        <FormattedMessage id="ManageSubscriptionPanel.amountHeading" />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {userSubscriptions?.length ? (
                      userSubscriptions.map((subscription, i) => (
                        <tr key={subscription?.id} className={css.subscriptionData}>
                          <td className={css.leftTd}>
                            <div className={css.subscriptionPlanBody}>
                              {subscription.plan.nickname
                                ? subscription.plan.nickname
                                : firstLetterCapital(subscription.plan.nickname)}
                            </div>
                          </td>
                          <td className={css.centerTd}>
                            <div className={css.subscriptionPlanBody}>
                              {moment(subscription.current_period_start * 1000).format(
                                'MMM Do YYYY'
                              )}
                            </div>
                          </td>
                          <td className={css.rightTd}>
                            <div className={css.subscriptionPlanBody}>
                              &nbsp; $
                              {subscription?.plan?.amount ? subscription?.plan?.amount / 100 : 0}
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className={css.subscriptionData}>
                        <td className={css.leftTd}>
                          <div className={css.subscriptionPlanBody}>Free plan</div>
                        </td>
                        <td className={css.centerTd}>
                          <div className={css.subscriptionPlanBody}>
                            {formattedEndDate ? formattedEndDate : null}
                          </div>
                        </td>
                        <td className={css.rightTd}>
                          <div className={css.subscriptionPlanBody}>
                            &nbsp;
                            {'$' + 0}
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          ) : null}

          <Modal
            id="Subscription.cancel"
            isOpen={cancelModal}
            onClose={() => setCancelModal(false)}
            onManageDisableScrolling={() => {}}
            className={css.subCancelModal}
          >
            <div className={css.cancelText}>Are you sure you want to cancel your subscription?</div>
            <div className={css.actionButton}>
              <button
                className={css.confirmButton}
                onClick={() => handleCancelSubscription(subscriptionId)}
              >
                Yes Cancel
              </button>
              <button className={css.cancelButton} onClick={() => setCancelModal(false)}>
                No Keep Subscription
              </button>
            </div>
          </Modal>
        </div>
      ) : (
        <SkeletonLoader />
      )}
    </>
  );
};

export default ManageSubscriptionPanel;
