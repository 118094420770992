/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { FormattedMessage } from '../../../util/reactIntl';
import { CLIENT, MODEL, propTypes } from '../../../util/types';
import { ensureCurrentUser, ensureOwnListing } from '../../../util/data';

import {
  AvatarLarge,
  InlineTextButton,
  Modal,
  NamedLink,
  NotificationBadge,
} from '../../../components';

import css from './TopbarMobileMenu.module.css';
import { getUserType } from '../../../util/helper';
import { createSlug } from '../../../util/urlHelpers';

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUser,
    notificationCount,
    onLogout,
    currentUserListing,
    onManageDisableScrolling,
    listingStatus,
  } = props;

  const user = ensureCurrentUser(currentUser);
  const listing = ensureOwnListing(currentUserListing);
  const userType = getUserType(currentUser);
  const [profileModal, setProfileModal] = useState(false);
  const { isFreePlanUsed } = currentUser?.attributes?.profile?.protectedData?.freePlanData || {};
  const { currentlyActive } = currentUser?.attributes?.profile?.protectedData || {};
  const isListing = currentUser?.attributes?.profile?.publicData?.listingId ? true : false;

  if (!isAuthenticated) {
    const signup = (
      <NamedLink name="SignupPage" className={css.signupLink}>
        <FormattedMessage id="TopbarMobileMenu.signupLink" />
      </NamedLink>
    );

    const login = (
      <NamedLink name="LoginPage" className={css.loginLink}>
        <FormattedMessage id="TopbarMobileMenu.loginLink" />
      </NamedLink>
    );

    const signupOrLogin = (
      <span className={css.authenticationLinks}>
        <FormattedMessage id="TopbarMobileMenu.signupOrLogin" values={{ signup, login }} />
      </span>
    );
    return (
      <div className={css.root}>
        <div className={css.content}>
          <NamedLink className={css.menuLink} name="SearchPage">
            <FormattedMessage id="TopbarMobileMenu.modelingJobs" />
          </NamedLink>
          <NamedLink className={css.menuLink} name="ProfileSettingsPage">
            <FormattedMessage id="TopbarMobileMenu.postJobs" />
          </NamedLink>
          <NamedLink className={css.menuLink} name="SearchPage">
            <FormattedMessage id="TopbarMobileMenu.modelingClass" />
          </NamedLink>
          <NamedLink className={css.menuLink} name="SearchPage">
            <FormattedMessage id="TopbarMobileMenu.aboutUs" />
          </NamedLink>
          <NamedLink className={css.menuLink} name="SearchPage">
            <FormattedMessage id="TopbarMobileMenu.blog" />
          </NamedLink>

          <NamedLink className={css.menuLink} name="SearchPage">
            <FormattedMessage id="TopbarMobileMenu.modelingJobs" />
          </NamedLink>
          <NamedLink className={css.menuLink} name="SearchPage">
            <FormattedMessage id="TopbarMobileMenu.models" />
          </NamedLink>
        </div>
        <div className={css.footer}>
          <NamedLink name="SignupPage" className={classNames(css.signupLink, css.menuLink)}>
            <FormattedMessage id="TopbarDesktop.signup" />
          </NamedLink>{' '}
          <NamedLink name="LoginPage" className={classNames(css.loginLink, css.menuLink)}>
            <FormattedMessage id="TopbarDesktop.login" />
          </NamedLink>
        </div>
      </div>
    );
  }
  const ModelingJobsLink = (
    <NamedLink className={css.navigationLink} name="SearchClientPage">
      <FormattedMessage id="TopbarDesktop.modelingJobs" />
    </NamedLink>
  );
  const ModelsLink = (
    <NamedLink className={css.navigationLink} name="SearchPage">
      <FormattedMessage id="TopbarDesktop.models" />
    </NamedLink>
  );

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const displayName = user.attributes.profile.firstName;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const completeProfileLink = manageProfileLink => {
    const namedLinkClass = manageProfileLink
      ? classNames(css.menuLink, currentPageClass('ManageListingsPage'))
      : css.menuLink;

    const namedLinkChild = manageProfileLink ? (
      <span className={css.profileSettingsLink}>
        <span className={css.menuItemBorder} />
        {userType === CLIENT ? <FormattedMessage id="TopbarDesktop.yourListingsLink" /> : null}
      </span>
    ) : (
      <>
        {isFreePlanUsed || currentlyActive ? (
          <span className={css.createListing}>
            <FormattedMessage id="TopbarDesktop.completeYourProfile" />
          </span>
        ) : null}
      </>
    );

    if (user && userType === MODEL) {
      if (listing && listing.attributes.state && listing.attributes.state === 'draft') {
        const id = listing.id.uuid;
        const { title = '' } = listing.attributes;
        const slug = createSlug(title);

        return (
          <NamedLink
            className={css.listingPage}
            name="EditListingPage"
            params={{ id, slug, type: 'draft', tab: 'details' }}
          >
            {namedLinkChild}
          </NamedLink>
        );
      } else if (!(currentUserHasListings ?? true)) {
        const draftId = '00000000-0000-0000-0000-000000000000';
        const draftSlug = 'draft';
        return (
          <NamedLink
            className={namedLinkClass}
            name="EditListingPage"
            params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'details' }}
          >
            {namedLinkChild}
          </NamedLink>
        );
      } else if (
        listing &&
        listing.attributes.state &&
        listing.attributes.state === 'pendingApproval'
      ) {
        const id = listing.id.uuid;
        const { title = '' } = listing.attributes;
        const slug = createSlug(title);
        return (
          <NamedLink
            className={namedLinkClass}
            name="ListingPageVariant"
            params={{ id, slug, variant: 'pending-approval' }}
          >
            {namedLinkChild}
          </NamedLink>
        );
      }
      //  } else if (currentUserListing && manageProfileLink ) {
      //   return (
      //     <NamedLink
      //       name="ListingPage"
      //       className={namedLinkClass}
      //       params={{
      //         id: currentUserListing.id.uuid,
      //         slug: createSlug(currentUserListing.attributes.title),
      //       }}
      //     >
      //       {namedLinkChild}
      //     </NamedLink>
      //   );
      // }
    }

    return <></>;
  };

  const editStylistProfileLink =
    userType === MODEL && listing.id ? (
      <NamedLink
        name="EditListingPage"
        className={classNames(css.navigationLink, currentPageClass('EditListingPage'))}
        params={{
          slug: createSlug(listing.attributes.title || ''),
          id: listing.id.uuid,
          type: 'edit',
          tab: 'details',
        }}
      >
        <FormattedMessage id="TopbarDesktop.editProfile" />
      </NamedLink>
    ) : (
      <></>
    );
  const viewProfileLink =
    user && userType === MODEL && listing.id ? (
      <NamedLink
        name="ListingPage"
        className={classNames(css.navigationLink, currentPageClass('ListingPage'))}
        params={{
          id: currentUser?.attributes?.profile?.publicData?.listingId || 'tet',
          slug: currentUser?.attributes?.profile?.publicData?.listingSlug || 'gnhg',
        }}
      >
        <FormattedMessage id="TopbarDesktop.viewProfile" />
      </NamedLink>
    ) : (
      <></>
    );
  return (
    <div className={css.root}>
      <AvatarLarge className={css.avatar} user={currentUser} />
      <div className={css.content}>
        <span className={css.greeting}>
          <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
        </span>
        <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </InlineTextButton>
        {completeProfileLink()}
        {ModelingJobsLink}
        {ModelsLink}
        {userType === MODEL && currentlyActive === 'Annual Plan' ? (
          <NamedLink className={css.navigationLink} name="ClassroomPage">
            <FormattedMessage id="TopbarDesktop.classrooms" />
          </NamedLink>
        ) : null}
        <NamedLink className={css.navigationLink} name="FavListingPage">
          <FormattedMessage id="TopbarDesktop.favListing" />
        </NamedLink>
        <NamedLink
          className={classNames(css.inbox, currentPageClass('InboxPage'))}
          name="InboxPage"
          params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
        >
          <FormattedMessage id="TopbarMobileMenu.inboxLink" />
          {notificationCountBadge}
        </NamedLink>
        {user && userType === CLIENT ? (
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <FormattedMessage id="TopbarMobileMenu.yourListingsLink" />
          </NamedLink>
        ) : null}
        {editStylistProfileLink}
        {listingStatus == 'published' ? viewProfileLink : null}
        {userType === CLIENT ? (
          <div className={css.footer}>
            <NamedLink className={css.createNewListingLink} name="NewListingPage">
              <FormattedMessage id="TopbarMobileMenu.newListingLink" />
            </NamedLink>
          </div>
        ) : null}

        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('AccountSettingsPage'))}
          name="AccountSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
        </NamedLink>

        {/* <div className={css.spacer} /> */}
        {userType === MODEL && currentlyActive === 'Annual Plan' ? (
          <NamedLink
            name="ListingPage"
            className={css.navigationLink}
            params={{
              id: currentUser?.attributes?.profile?.publicData?.listingId || 'tet',
              slug: currentUser?.attributes?.profile?.publicData?.listingSlug || 'gnhg',
            }}
          >
            <FormattedMessage id="TopbarDesktop.viewProfile" />
          </NamedLink>
        ) : null}
      </div>
      {user && userType == MODEL ? (
        <></>
      ) : (
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ProfileSettingsPage'))}
          name="ProfileSettingsPage"
        >
          <span className={css.menuItemBorder} />
          <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
        </NamedLink>
      )}
      {userType === MODEL && !isFreePlanUsed && !currentlyActive ? (
        <span onClick={() => setProfileModal(true)} className={css.completeProfile}>
          <FormattedMessage id="TopbarDesktop.completeYourProfile" />
        </span>
      ) : null}

      {/* </NamedLink> */}
      <div>
        <Modal
          id="MissingInformationReminder"
          isOpen={profileModal}
          usePortal
          onClose={() => setProfileModal(false)}
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <div className={css.needSubscribe}>
            <p>Need to subscribe first before creating profile</p>

            <NamedLink name="StripeSubscriptionPage">Go to subscription Page</NamedLink>
          </div>
        </Modal>
      </div>
    </div>
  );
};

TopbarMobileMenu.defaultProps = { currentUser: null, notificationCount: 0, currentPage: null };

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
