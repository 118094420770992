import React, { useState } from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { displayPrice } from '../../util/configHelpers';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { COLLABORATION, MODEL, PROFESSIONAL_ANNUALY, PROFESSIONAL_HALFYEARLY, PROFESSIONAL_MONTHLY, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';

import {
  AspectRatioWrapper,
  Button,
  IconCard,
  Modal,
  NamedLink,
  ResponsiveImage,
} from '../../components';

import css from './ListingCard.module.css';
import { updateProfile } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { createResourceLocatorString } from '../../util/routes';
import { manageDisableScrolling } from '../../ducks/ui.duck';
import { getSubscriptionStatus, getUserType } from '../../util/helper';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

const PriceMaybe = props => {
  const { price, publicData, config, intl } = props;
  const { listingType } = publicData || {};
  const validListingTypes = config.listing.listingTypes;
  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice && price) {
    return null;
  }

  const isBookable = isBookingProcessAlias(publicData?.transactionProcessAlias);
  const { formattedPrice, priceTitle } = priceData(price, config.currency, intl);
  return (
    <div className={css.price}>
      <div className={css.priceValue} title={priceTitle}>
        {formattedPrice}
      </div>
      {isBookable ? (
        <div className={css.perUnit}>
          <FormattedMessage id="ListingCard.perUnit" values={{ unitType: publicData?.unitType }} />
        </div>
      ) : null}
    </div>
  );
};

export const ListingCardComponent = props => {
  const config = useConfiguration();
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
    currentUser,
    isClientSearch,
    history,
  } = props;
  const isCurrentUserProfile = currentUser?.profileImage?.attributes?.variants?.['square-small']
    ?.url
    ? true
    : false;
  const userType = getUserType(currentUser);
  const routeConfiguration = useRouteConfiguration();
  const [isProfileModal, setIsProfileModal] = useState(false);
  const [isSubscriptionModal, setIsSubscriptionModal] = useState(false);
  const dispatch = useDispatch();
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);

  const id = currentListing.id.uuid;
  const { title = '', price, publicData } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const { listingType,castingEndDate,jobEndDate,jobStartDate,location } = publicData || {};
  
  const modelProfileImage = author?.profileImage;
  const clientProfileImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const firstImage = listingType === MODEL ? modelProfileImage : clientProfileImage;
  const isModel = listingType === MODEL ? true : false;
  const isprofessionalModal = publicData?.isProfessional == true;
  
  
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;

  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];
  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(currentListing.id),
        onMouseLeave: () => setActiveListing(null),
      }
    : null;

  const { favouriteItems = [] } =
    (!!currentUser?.id && currentUser.attributes.profile.protectedData) || {};
  const index = favouriteItems && favouriteItems.findIndex(e => e == currentListing.id.uuid);
  const handleFavouriteItems = () => {
    if (!currentUser) {
      const state = { from: `${location.pathname}${location.search}${location.hash}` };
      history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), state);
    } else {
      if (index > -1) {
        favouriteItems.splice(index, 1);
        const addedfavouriteItems = Array.from(new Set(favouriteItems));
        const profile = {
          protectedData: {
            favouriteItems: addedfavouriteItems,
          },
        };
        dispatch(updateProfile(profile));
      } else {
        favouriteItems.push(currentListing.id.uuid);
        const addedfavouriteItems = Array.from(new Set(favouriteItems));
        const profile = {
          protectedData: {
            favouriteItems: addedfavouriteItems,
          },
        };
        dispatch(updateProfile(profile));
      }
    }
  };

  const isUserSubscribed = getSubscriptionStatus(currentUser);
  
  return (
    <div>
      <div className={isClientSearch ? css.listingCardWrapperModeling : css.listingCardWrapper}>
        <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
          {!isClientSearch ? (
            <AspectRatioWrapper
              className={css.aspectRatioWrapper}
              width={aspectWidth}
              height={aspectHeight}
              {...setActivePropsMaybe}
            >
              <LazyImage
                rootClassName={css.rootForImage}
                alt={title}
                image={firstImage}
                variants={variants}
                sizes={renderSizes}
              />
            </AspectRatioWrapper>
          ) : null}
          {isModel ? (
            <div className={css.info}>
              <div className={css.mainInfo}>
                <div className={css.topBarTitle}>
                  <div className={css.modelName}>
                    <span className={css.title}>
                      {richText(title, {
                        longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                        longWordClass: css.longWord,
                      })}
                    </span>
                    <span className={css.verifiedIcon}>
                      {isprofessionalModal == true ? <IconCard brand="verified" /> : null}
                    </span>
                  </div>
                  <div
                    className={css.favorite}
                    onClick={e => {
                      e.preventDefault();
                      handleFavouriteItems();
                    }}
                  >
                    {index > -1 ? (
                      <span className={css.bookmark}>
                        <IconCard brand="like" />
                      </span>
                    ) : (
                      <span>
                        <IconCard brand="dislike" />
                      </span>
                    )}
                  </div>
                </div>
                {showAuthorInfo ? <div className={css.authorInfo}>{location?.address}</div> : null}
              </div>
            </div>
          ) : (
            <div className={css.jobProfileInfo}>
              <div className={css.mainInfo}>
                <div className={css.title}>
                  {richText(title, {
                    longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                    longWordClass: css.longWord,
                  })}
                </div>
                {location && location.address !== null ? (
                  <div className={css.authorAddress}>
                    <IconCard brand="address" />
                    <span>{location?.address}</span>
                  </div>
                ) : null}
                <div className={css.authorAddress}>
                  <IconCard brand="timer" />
                  <span>Casting ends- {castingEndDate}</span>
                </div>
                <div className={css.authorAddress}>
                  <IconCard brand="timer" />
                  <span>Job starts- {jobStartDate}</span>
                </div>
                <div className={css.authorAddress}>
                  <IconCard brand="timer" />
                  <span>Job ends- {jobEndDate}</span>
                </div>
                <div className={css.authorAddress}>
                  <IconCard brand="dollarsecond" />
                  <span>
                    {publicData?.jobInformation == 'collaboration' ? 'Collaboration' : 'Paid'}
                  </span>
                </div>
              </div>
            </div>
          )}
        </NamedLink>
        {isClientSearch ? (
          <div className={css.profileApplyButton}>
            {userType === MODEL && !isUserSubscribed  ?
              <Button
              type="button"
              onClick={() => setIsSubscriptionModal(true)}
              className={css.createNewListingLink}
            >
              Apply
            </Button>
            :!isCurrentUserProfile && userType === 'model' ? (
              <Button
                type="button"
                onClick={() => setIsProfileModal(true)}
                className={css.createNewListingLink}
              >
                Apply
              </Button>
            ) : (
              <NamedLink className={css.applyButton} name="ListingPage" params={{ id, slug }}>
                Apply
              </NamedLink>
            )}
          </div>
        ) : null}
      </div>

      <Modal
        className={css.applyJobModal}
        id="ProfileRouteModal"
        isOpen={isSubscriptionModal}
        onClose={() => setIsSubscriptionModal(false)}
        onManageDisableScrolling={(componentId, disableScrolling) =>
          dispatch(manageDisableScrolling(componentId, disableScrolling))
        }
      >
        <div>
        <p>Unlock Modeling Opportunities!</p>
          <p>
            To apply to modeling jobs, you need an active subscription. Click the button below to
            subscribe and start applying to modeling jobs.
          </p>
          <NamedLink name="StripeSubscriptionPage">Go to subscription Page</NamedLink>
        </div>
      </Modal>
      <Modal
        className={css.applyJobModal}
        id="ProfileRouteModal"
        isOpen={isProfileModal}
        onClose={() => setIsProfileModal(false)}
        onManageDisableScrolling={(componentId, disableScrolling) =>
          dispatch(manageDisableScrolling(componentId, disableScrolling))
        }
      >
        <div className={css.termsWrapper}>
          <h4>You need to complete your profile before applying for any jobs</h4>
          <NamedLink
            className={css.applyButton}
            name="EditListingPage"
            params={{
              slug,
              id,
              type: 'new',
              tab: 'details',
            }}
          >
            Go to profile page
          </NamedLink>
        </div>
      </Modal>
    </div>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,
  scrollingDisabled: bool?.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
