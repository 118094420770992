import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape } from '../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { CLIENT, MODEL, propTypes } from '../../../util/types';
import {
  Avatar,
  InlineTextButton,
  LinkedLogo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  Modal,
  IconCard,
} from '../../../components';

import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';

import css from './TopbarDesktop.module.css';
import Icons from '../../Icons/Icons';
import { getUserType } from '../../../util/helper';
import { ensureCurrentUser, ensureOwnListing } from '../../../util/data';
import { createSlug } from '../../../util/urlHelpers';

const TopbarDesktop = props => {
  const {
    className,
    appConfig,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    currentUserListing,
    onManageDisableScrolling,
    listingStatus,
  } = props;
  
  const [mounted, setMounted] = useState(false);
  const [profileModal, setProfileModal] = useState(false);
  const userType = getUserType(currentUser);
  const { isFreePlanUsed } = currentUser?.attributes?.profile?.protectedData?.freePlanData || {};
  const { currentlyActive } = currentUser?.attributes?.profile?.protectedData || {};

  useEffect(() => {
    setMounted(true);
  }, []);
  const user = ensureCurrentUser(currentUser);
  const listing = ensureOwnListing(currentUserListing);
  const isEmailVerified = user.attributes.emailVerified;

  const completeProfileLink = manageProfileLink => {
    const namedLinkClass = manageProfileLink
      ? classNames(css.menuLink, currentPageClass('ManageListingsPage'))
      : css.menuLink;

    const namedLinkChild = manageProfileLink ? (
      <span className={css.profileSettingsLink}>
        <span className={css.menuItemBorder} />
        {userType === CLIENT ? <FormattedMessage id="TopbarDesktop.yourListingsLink" /> : null}
      </span>
    ) : (
      <>
        {isFreePlanUsed || currentlyActive ? (
          <span className={css.createListing}>
            <FormattedMessage id="TopbarDesktop.completeYourProfile" />
          </span>
        ) : null}
      </>
    );

    // routing according to conditions(new user redirect to create listing and if listing is in draft than redirect to draft listing)
    if (user && isEmailVerified && user.attributes.profile.publicData?.userType == MODEL) {
      if (listing && listing.attributes.state && listing.attributes.state === 'draft') {
        const id = listing.id.uuid;
        const { title = '' } = listing.attributes;
        const slug = createSlug(title);

        return (
          <NamedLink
            className={namedLinkClass}
            name="EditListingPage"
            params={{ id, slug, type: 'draft', tab: 'details' }}
          >
            {namedLinkChild}
          </NamedLink>
        );
      } else if (!(currentUserHasListings ?? true)) {
        const draftId = '00000000-0000-0000-0000-000000000000';
        const draftSlug = 'draft';
        return (
          <NamedLink
            className={namedLinkClass}
            name="EditListingPage"
            params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'details' }}
          >
            {namedLinkChild}
          </NamedLink>
        );
      } else if (
        listing &&
        listing.attributes.state &&
        listing.attributes.state === 'pendingApproval'
      ) {
        const id = listing.id.uuid;
        const { title = '' } = listing.attributes;
        const slug = createSlug(title);
        return (
          <NamedLink
            className={namedLinkClass}
            name="ListingPageVariant"
            params={{ id, slug, variant: 'pending-approval' }}
          >
            {namedLinkChild}
          </NamedLink>
        );
      } else if (currentUserListing && manageProfileLink) {
        return (
          <NamedLink
            name="ListingPage"
            className={namedLinkClass}
            params={{
              id: currentUserListing.id.uuid,
              slug: createSlug(currentUserListing.attributes.title || ''),
            }}
          >
            {namedLinkChild}
          </NamedLink>
        );
      }
    }

    return <></>;
  };

  const editStylistProfileLink =
    user && userType === MODEL && listing.id ? (
      <NamedLink
        name="EditListingPage"
        className={css.menuLink}
        params={{
          slug: createSlug(listing.attributes.title || ''),
          id: listing.id.uuid,
          type: 'edit',
          tab: 'details',
        }}
      >
        <FormattedMessage id="TopbarDesktop.editProfile" />
      </NamedLink>
    ) : (
      <></>
    );
  const viewProfileLink =
    user && userType === MODEL && listing.id ? (
      <NamedLink
        name="ListingPage"
        className={css.menuLink}
        params={{
          id: currentUser?.attributes?.profile?.publicData?.listingId || 'tet',
          slug: currentUser?.attributes?.profile?.publicData?.listingSlug || 'gnhg',
        }}
      >
        <FormattedMessage id="TopbarDesktop.viewProfile" />
      </NamedLink>
    ) : (
      <></>
    );

  const marketplaceName = appConfig.marketplaceName;
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = classNames(rootClassName || css.root, className);

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      appConfig={appConfig}
    />
  );

  const notificationDot =
    (notificationCount > 0) | true ? <div className={css.notificationDot} /> : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.navLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const profileMenu = authenticatedOnClientSide ? (
    <Menu className={css.dropdownMenu}>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        {userType != MODEL ? (
          <MenuItem key="ManageListingsPage">
            <NamedLink
              className={classNames(css.menuLink, currentPageClass('ManageListingsPage'))}
              name="ManageListingsPage"
            >
              <FormattedMessage id="TopbarDesktop.yourListingsLink" />
            </NamedLink>
          </MenuItem>
        ) : (
          <MenuItem key="ManageListingsPage">{completeProfileLink(true)}</MenuItem>
        )}
        <MenuItem key="EditListingPage">{editStylistProfileLink}</MenuItem>
        <MenuItem key="ListingPage">
          {listingStatus == 'Published' ? viewProfileLink : null}
        </MenuItem>

        <MenuItem key="ProfileSettingsPage">
          {user && userType === MODEL ? (
            <></>
          ) : (
            <NamedLink
              className={classNames(css.menuLink, currentPageClass('ProfileSettingsPage'))}
              name="ProfileSettingsPage"
            >
              <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
            </NamedLink>
          )}
        </MenuItem>
        {/* <MenuItem key="ApplicantsPage">
          {user && userType === MODEL ? (
            <></>
          ) : (
            <NamedLink
              className={classNames(css.menuLink, currentPageClass('ProfileSettingsPage'))}
              name="ApplicantsPage"
            >
              <FormattedMessage id="TopbarDesktop.applicantsLink" />
            </NamedLink>
          )}
        </MenuItem> */}
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
          <NamedLink className={css.menuLink} name="FavListingPage">
            <FormattedMessage id="TopbarDesktop.favListing" />
          </NamedLink>

          {userType === MODEL && currentlyActive === 'Annual Plan' ? (
            <NamedLink className={css.menuLink} name="ClassroomPage">
              <FormattedMessage id="TopbarDesktop.classrooms" />
            </NamedLink>
          ) : null}
        </MenuItem>
        <MenuItem  key="ExternalAboutus">
        <a target='_Blank'
          className={css.menuLink}
          href="https://modelhire.co/about-us/"
        >
          <FormattedMessage id="TopbarDesktop.aboutUs" />
        </a>
      </MenuItem>
      <MenuItem key="BlogExternal">
        <a target='_Blank'
          className={css.menuLink}
          href="https://modelhire.co/blog/"
        >
          <FormattedMessage id="TopbarDesktop.blog" />
        </a>
      </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const otherLinksMenu = !authenticatedOnClientSide ? (
    <Menu className={css.dropdownMenu}>
      <MenuLabel className={css.menuIcon} isOpenClassName={css.profileMenuIsOpen}>
        <Icons name="menu" />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="ManageListingsPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <FormattedMessage id="TopbarMobileMenu.modelingJobs" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <FormattedMessage id="TopbarMobileMenu.postJobs" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <FormattedMessage id="TopbarMobileMenu.modelingClass" />
          </NamedLink>
        </MenuItem>

        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <FormattedMessage id="TopbarMobileMenu.aboutUs" />
          </NamedLink>
        </MenuItem>

        <MenuItem key="StripeSubscriptionPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('StripeSubscriptionPage'))}
            name="StripeSubscriptionPage"
          >
            <FormattedMessage id="TopbarMobileMenu.stripeSubscription" />
          </NamedLink>
        </MenuItem>

        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <FormattedMessage id="TopbarMobileMenu.blog" />
          </NamedLink>
        </MenuItem>
      
      </MenuContent>
    </Menu>
  ) : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={classNames(css.signupLink, css.navLink)}>
      <FormattedMessage id="TopbarDesktop.joinmodelhire" />
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.postLink}>
      <IconCard brand="write" />
      <FormattedMessage id="TopbarDesktop.signin" />
    </NamedLink>
  );
  const postJobLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.postLink}>
      <IconCard brand="user" />
      <FormattedMessage id="TopbarDesktop.postajob" />
    </NamedLink>
  );
  const ModelingJobsLink = (
    <NamedLink className={css.navLink} name="SearchClientPage">
      <FormattedMessage id="TopbarDesktop.modelingJobs" />
    </NamedLink>
  );
  const ClassroomJobsLink = (
    <NamedLink className={css.navLink} name="ClassroomPage">
      <FormattedMessage id="TopbarDesktop.classroom" />
    </NamedLink>
  );
  const ModelsLink = (
    <NamedLink className={css.navLink} name="SearchPage">
      <FormattedMessage id="TopbarDesktop.models" />
    </NamedLink>
  );

  return (
    <nav className={classes}>
      <div className={css.fixedWidthContainer}>
        <div className={css.leftLinks}>
          <LinkedLogo
            className={css.logoLink}
            layout="desktop"
            alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
          />
          {ModelingJobsLink}
          {ModelsLink}
          {ClassroomJobsLink}
        </div>
        {/* {search} */}
        <div className={css.rightLinks}>
          {' '}
          {userType === MODEL || (user && !user?.id) ? null : (
            <>
              <NamedLink className={css.navLink} name="NewListingPage">
                <FormattedMessage id="TopbarDesktop.createListing" />
              </NamedLink>
            </>
          )}
          {completeProfileLink()}
          {userType === MODEL && !isFreePlanUsed && !currentlyActive ? (
            <span onClick={() => setProfileModal(true)} className={css.navLink}>
              <FormattedMessage id="TopbarDesktop.completeYourProfile" />
            </span>
          ) : null}
          {loginLink}
          {postJobLink}
          {signupLink}
          {otherLinksMenu}
          {inboxLink}
          {profileMenu}
        </div>

        <Modal
          id="MissingInformationReminder"
          isOpen={profileModal}
          usePortal
          onClose={() => setProfileModal(false)}
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <div>
            <p>Need to subscribe first before creating profile</p>

            <NamedLink name="StripeSubscriptionPage">Go to subscription Page</NamedLink>
          </div>
        </Modal>
      </div>
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  appConfig: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  appConfig: object,
};

export default TopbarDesktop;
